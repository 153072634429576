import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { SEO, Text } from '../components/common';
import HeroSection from '../components/HeroSection';
import { ServicePage } from '../@types/types';
import PriceSection from '../components/PriceSection';
import ThreeSquareSection from '../components/ThreeSquareSection';
import { NextPage } from '../components/SVG';
import InfoSection from '../components/InfoSection';

type Props = {
  data: { page: ServicePage; arrow: { publicURL: string } };
  location: Location;
};

const Services = ({ data, location }: Props) => {
  const {
    page: {
      MetaData,
      PageTopSection,
      Instructions,
      PriceInfo,
      PriceTable,
      ThreeBlockSection,
      FeaturedServices,
    },
    arrow,
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />
      <ThreeSquareSection
        content={ThreeBlockSection}
        colors={['lightBlue', 'lightBlue', 'lightBlue']}
        FirstButtons={FeaturedServices.map((service) => (
          <ServiceButton key={service.slug} href={`/palvelut/${service.slug}`}>
            <Text variant="title-4">{service.Name}</Text>
            <NextPage width="34" />
          </ServiceButton>
        ))}
        SecondButtons={
          <ServiceButton href="/perhepalvelut/">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage width="34" />
          </ServiceButton>
        }
        ThirdButtons={
          <ServiceButton href="/palvelut/psykoterapia">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage />
          </ServiceButton>
        }
      />
      <InfoSection
        header="Ohjeet"
        instructions={Instructions}
        bgColor="white"
      />
      <PriceSection
        header="Hinnasto"
        priceData={PriceTable}
        bgColor="blue"
        width="200"
        text={PriceInfo}
        image={arrow.publicURL}
      />
    </>
  );
};

export default Services;

export const serviceQuery = graphql`
  query ServicePageQuery {
    page: strapiPalvelutJaHinnasto {
      ...strapiPalvelutJaHinnastoFragment
    }
    arrow: file(name: { eq: "blue-arrow" }) {
      publicURL
    }
  }
`;

const ServiceButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 10px 20px;
  text-decoration: none;
  margin-bottom: ${(props) => props.theme.spacing.default};
`;
